<template>
  <layout>
    <template slot="headerMenu">
      <div class="header-menu">
        <div class="header-menu-wraper">
          <div class="header-menu-title">个人提升</div>
          <a-menu mode="horizontal" v-model="currentMenu">
            <template v-for="item in topMenuList">
              <a-menu-item :key="item.name" v-if="!item.hidden" >
                <div @click="navEvt(item)">{{ item.meta.title }}</div>
              </a-menu-item>
            </template>
          </a-menu>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "index",
  computed:{
    ...mapGetters(['addRouter'])
  },
  data(){
    return{
      currentMenu:['PersonalDevelopmentCourse'],
      topMenuList : []
    }
  },
  methods:{
    navEvt(item){
      this.$router.push({
        path:item.path
      })
    }
  },
  created() {
    this.currentMenu = [this.$route.name]
    this.topMenuList = this.addRouter.filter(item=>item.name==='PersonalDevelopment')[0].children

    console.log(this.topMenuList)
  }
}
</script>

<style scoped>
</style>